<template>
  <div v-if="isShow" class="alert-container flex-row flex-center flex-middle" @click="closeSelf">
    <div class="content-view flex-row flex-center" @click.stop="">
      <img :src="closeImgUrl" class="close-img" @click="closeSelf" />
      <div class="title1">{{alertData.title1 || title1}}</div>
      <div class="title2">{{alertData.title2 || title2}}</div>
      <img :src="alertData.qrcodeUrl || qrcodeUrl" class="qr-img"  />
      <div class="title3">{{alertData.title3 || title3}}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    alertData: {
      type: Object
    }
  },
  data() {
    return {
      closeImgUrl: require('@/assets/alert/close.png'),
      qrcodeUrl: require('@/assets/footer/qr_contact.jpeg'),
      isShow: false,
      title1: '扫码咨询',
      title2: '请用微信扫码添加客服：耳穴堂明明',
      title3: '请使用微信扫描二维码',
    }
  },
  methods: {
    closeSelf() {
      this.isShow = false
    },
    showSelf() {
      this.isShow = true
    }
  }
}
</script>

<style lang="less" scoped>
  .alert-container {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 20000;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, .6);
  }

  // *******************内容******************************** */
  .content-view {
    position: relative;
    width: 656px;
    box-sizing: border-box;
    padding: 70px 70px 126px;
    background: #FFFFFF;
    border-radius: 10px;
    .close-img {
      position: absolute;
      right: 30px;
      top: 30px;
      width: 26px;
      height: 26px;
    }
    .title1 {
      width: 100%;
      text-align: left;
      font-size: 50px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #282828;
      line-height: 36px;
    }
    .title2 {
      width: 100%;
      text-align: left;
      margin-top: 20px;
      font-size: 26px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #565656;
      line-height: 30px;
    }
    .qr-img {
      margin-top: 30px;
      width: 280px;
      height: 280px;
    }
    .title3 {
      margin-top: 30px;
      font-size: 22px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #565656;
      line-height: 30px;
    }
  }
  // *******************内容******************************** */


</style>