import Vue from 'vue'
import Router from 'vue-router'

const routes = [
  {
    path: '/',
    component: () => import( /* webpackChunkName: "fail" */ '@/views/Home')
  } ,{
    path: '/enterEXK',
    component: () => import( /* webpackChunkName: "fail" */ '@/views/EnterEXK')
  }, {
    path: '/juniorCamp',
    component: () => import( /* webpackChunkName: "fail" */ '@/views/JuniorCamp')
  }, {
    path: '/seniorCamp',
    component: () => import( /* webpackChunkName: "fail" */ '@/views/SeniorCamp')
  }, {
    path: '/aboutUS',
    component: () => import( /* webpackChunkName: "fail" */ '@/views/AboutUS')
  }
]

Vue.use(Router)

const originalPush = Router.prototype.push

Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

export default new Router({
  mode: 'history',
  routes
})
