/*
 * @Description: 
 * @Version: 0.0
 * @Autor: Zippo
 * @Date: 2021-05-06 16:29:29
 * @LastEditors: Zippo
 * @LastEditTime: 2021-05-06 16:36:14
 */
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

let store = new Vuex.Store({
  state: {
    isCheck: true // 是否在审核中
  },
  mutations: {

  },
  getters: {

  },
  actions: {

  }
})

export default store