<template>
  <div>
    <!-- <div class="contacts-block fixed-container flex-row flex-center flex-middle" @click="contactTap">
      <img src="@/assets/icons/xf_kfzx_icon@2x.png" class="fixed-img"/>
      <div class="title">客服咨询</div>
    </div> -->

    <div class="middle-block fixed-container flex-row flex-center">
      <div class="container1 flex-row flex-center flex-middle" @mouseenter="itemTap(1)" @mouseleave="itemLeave(1)">
        <img :src="showMiniprogramQR ? miniprogramIconSelected:miniprogramIcon" class="fixed-img"/>
        <div class="title">小程序</div>
        <img v-if="showMiniprogramQR" :src="miniprogramQR" class="qr-img" />
      </div>
      <div class="space"></div>
      <div class="container1 flex-row flex-center flex-middle" @mouseenter="itemTap(2)" @mouseleave="itemLeave(2)">
        <img :src="showOfficialQR ? officialIconSelected:officialIcon" class="fixed-img"/>
        <div class="title">公众号</div>
        <img v-if="showOfficialQR" :src="officialQR" class="qr-img" />
      </div>
      <div v-if="gotop" class="goto-top flex-row flex-center flex-middle" @click="toTop">
        <img src="@/assets/icons/xf_top_icon@2x.png" class="fixed-img"/>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      gotop: false,
      miniprogramQR: require('@/assets/miniprogram.png'),
      officialQR: require('@/assets/official.jpg'),
      showMiniprogramQR: false,
      showOfficialQR:false,
      miniprogramIcon: require('@/assets/icons/xf_xcx_icon@2x.png'),
      miniprogramIconSelected: require('@/assets/icons/xf_xcx_click@2x.png'),
      officialIcon: require('@/assets/icons/xf_gxh_icon@2x.png'),
      officialIconSelected: require('@/assets/icons/xf_gxh_click@2x.png'),
    }
  },
  mounted(){
    // 此处true需要加上，不加滚动事件可能绑定不成功
    window.addEventListener("scroll", this.handleScroll, true);
  },
  methods: {
    handleScroll() {
       let scrolltop = document.documentElement.scrollTop || document.body.scrollTop;
      scrolltop > 30 ? (this.gotop = true) : (this.gotop = false);
    },
    toTop() {
      
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      // 实现滚动效果 
      const timeTop = setInterval(() => {
        document.body.scrollTop = document.documentElement.scrollTop = top -= 50;
        if (top <= 0) {
          clearInterval(timeTop);
        }
      }, 10);
    },

    itemTap(idx) {
      if (idx == 1) {
        // 小程序
        this.showMiniprogramQR = true
      }else if (idx == 2) {
        this.showOfficialQR = true
      }
    },
    itemLeave(idx) {
      if (idx == 1) {
        // 小程序
        this.showMiniprogramQR = false
      }else if (idx == 2) {
        this.showOfficialQR = false
      }
    }
  }
}
</script>

<style lang="less" scoped>
  // 滑动到顶部
  .goto-top{
    position: absolute;
    right: 0;
    left: 0;
    margin: auto;
    top: calc(100% + 10px);
    height: 60px;
    background: #FFFFFF;
    border: 1px solid #EDEDED;
  }

  // 共同用
  .fixed-container {
    position: fixed;
    right: 0;
    width: 72px;
    height: 60px;
    background: #FFFFFF;
    border: 1px solid #EDEDED;
  }
  .fixed-img {
    width: 32px;
    height: 32px;
  }
  .title {
    margin-top: 4px;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 20px;
  }

  // 客服
  .contacts-block {
    top: 563px;
    width: 72px;
    height: 76px;
    background: #317BFF;
    border-radius: 6px 0px 0px 6px;
    .title {
      color: #FFFFFF;
    }
  }

  // 
  .middle-block  {
    z-index: 10000;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    width: 72px;
    height: 152px;
    background: #FFFFFF;
    border: 1px solid #EDEDED;
    .container1 {
      position: relative;
      width: 100%;
      height: 75px;
      .qr-img {
        position: absolute;
        right: 92px;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 117px;
        height: 117px;
        padding: 10px;
        background-color: white;
        border: 1px solid #EDEDED;
      }
      &:hover {
        color: #317BFF;
      }
    }
    .space {
      width: 53px;
      height: 1px;
      background: #EDEDED;
    }
  }
</style>