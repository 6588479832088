<template>
  <div id="app">
    <Header></Header>
    <router-view></router-view>
    <Footer></Footer>
    <Suspension></Suspension>
  </div>
</template>

<script>
import Header from "./components/Header";
import Footer from "./components/Footer";
import Suspension from "./components/Suspension";


export default {
  name: 'App',
  components: {
    Header,
    Footer,
    Suspension
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

:root {
  overflow-y: auto;
  overflow-x: hidden;
}
:root body {
  position: absolute;
}
body {
  width: 100vw;
}
</style>
