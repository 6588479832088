/*
 * @Description: 
 * @Version: 0.0
 * @Autor: Zippo
 * @Date: 2021-03-15 10:15:22
 * @LastEditors: Zippo
 * @LastEditTime: 2021-05-06 16:30:49
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueMeta from 'vue-meta'

import 'normalize.css'
import '@/assets/css/flex-layout.css'
import '@/assets/css/common.css'
import '@/assets/css/base.less'

import VueAwesomeSwiper from 'vue-awesome-swiper'
// If you use Swiper 6.0.0 or higher
import 'swiper/swiper-bundle.css'

import preview from 'vue-photo-preview'
import 'vue-photo-preview/dist/skin.css'
// import less from 'less'

Vue.config.productionTip = false

// Vue.use(less)
Vue.use(router)
Vue.use(VueAwesomeSwiper, /* { default options with global component } */ )
Vue.use(preview)

Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})

new Vue({
  router,
  store,
  render: h => h(App),
  mounted() {
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount('#app')
