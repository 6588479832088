<template>
<div class="">
  <div class="header-container-default"></div>
  <div class="header-container flex-row flex-center flex-middle">
    <div class="main-width flex-column flex-middle">
      <img src="@/assets/logo2.png" class="logo-img" @click="menuTap(0)" />
      <!-- 中间菜单 -->
      <div class="menus flex-column flex-middle">
        <div v-for="item,index in menusArr" class="menu-item" :class="{'selected': selectedMenuIdx==index}" :key="item.title" @click="menuTap(index)">
          <span class="menu-item-text">{{item.title}}</span>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
    // 监听,当路由发生变化的时候执行
  watch:{
      $route(to){
        console.log('header');
        console.log(to);
          let path = to.path
          for (let i = 0; i < this.menusArr.length; i++) {
            const menuItem = this.menusArr[i];
            if (path == menuItem.path) {
              this.selectedMenuIdx = i
              break
            }
          }
      }
    },
  data() {
    return {
      menusArr: [
        {
          title: '首页',
          path: '/'
        },
        {
          title: '走进耳穴',
          path: '/enterEXK'
        },
        {
          title: '初级学习营',
          path: '/juniorCamp'
        },
        {
          title: '高级学习营',
          path: '/seniorCamp'
        },
        // {
        //   title: '专项病学习营',
        //   path: '/'
        // },
        {
        title: '关于我们',
        path: '/aboutUS',
      }
      ],

      selectedMenuIdx:0 //选中的菜单索引
    }
  },
  methods: {
    menuTap(idx) {
      this.selectedMenuIdx = idx
        let path = this.menusArr[idx].path
        this.$router.push(path)
    }
  }
}
</script>

<style lang="less" scoped>
  .header-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 64px;
    background: #FFFFFF;
    // padding: 20px 0;
    box-sizing: border-box;
    z-index: 1000;
  }
  .header-container-default {
    width: 100vw;
    height: 64px;
  }
  .logo-img {
    width: 136px;
    height: 43px;
    margin-right: 56px;
  }

  // *******************菜单******************************** */
  .menus {
    flex: 1;
    height: 100%;
  }

  .menu-item {
    padding: 12px 21px;
    height: 40px;
    box-sizing: border-box;
    border-radius: 20px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #444444;
    line-height: 20px;
    &:hover {
      background: #F6F6F6;
      color: #317BFF;
    
      .menu-item-text {
        font-weight: 700;
      }
    }

    &:last-child {
      margin-left: auto;
    }
  }
  .menu-item-text{
    padding-bottom: 18px;
  }
  .menu-item.selected {
    background: #F6F6F6;
    color: #317BFF;
  
    .menu-item-text {
      font-weight: 700;
      border-bottom: #317BFF solid 2px;
    }
  }
  // *******************菜单******************************** */

</style>