<template>
  <div class="footer-container flex-column flex-center">
    <!-- 介绍 -->
    <div class="introduce main-width flex-column flex-top ">
      <img src="@/assets/logo.png" class="logo-img" />
      <!-- 菜单 -->
      <div v-for="menuItem,index in bottomMenuArr" :key="index" class="menu-item flex-row flex-left">
        <div class="title">{{menuItem.title}}</div>
        <div v-for="subItem,subIdx in menuItem.itemArr" :key="subIdx" class="sub-item flex-column flex-middle" @click="bottomTap(subItem)">
          <img v-if="subItem.icon" :src="subItem.icon" class="icon" />
          <div class="title2" :class="{'could-tap':subItem.linkUrl != ''}"> {{subItem.subTitle}}</div>
        </div>
      </div>
      <!-- 右边二维码 -->
      <div class="qr-block right-view flex-column flex-top">
        <div v-for="qrItem,qrIdx in qrCodeArr" :key="qrIdx" class="qr-item flex-row flex-center">
          <img :src="qrItem.icon" class="qr-img"/>
          <div class="qr-title">{{qrItem.title}}</div>
        </div>
      </div>

    </div>
     <!-- 备案 -->
    <div class="record-div text-center">Copyright 2015 BY 耳穴库 All Right Reserved. 
      <a href="http://beian.miit.gov.cn/?spm=a2c4g.11186623.J_9220772140.115.26b47b3dr9P4fA" target="_blank" data-spm-anchor-id="a2c4g.11186623.J_9220772140.115">鲁ICP备15019531号</a>
      <img border="0" hspace="0" vspace="0" src="http://icon.cnzz.com/img/pic.gif">
    </div>

    <!-- <img v-if="isShowAD" @click="showAlertAction(contactsAlertData)" src="@/assets/bottom_ad_img.png" class="bottom-ad" /> -->

        <!-- 弹框 -->
    <qr-code-alert ref="codeAlert" :alertData="alertData"></qr-code-alert>
    <!-- 弹框End -->
  </div>
</template>

<script>

import QrCodeAlert from './QrCodeAlert'

export default {
  components: {
    QrCodeAlert
  },
  // 监听,当路由发生变化的时候执行
  watch:{
      // $route(to){
      //   if (to.path != '/') {
      //     this.isShowAD = false
      //   }else {
      //     this.isShowAD = true
      //   }
      // }
    },
  data() {
    return {
      isShowAD: true,
      // 菜单数组
      bottomMenuArr: [
        {
          title: '关于我们',
          itemArr: [
            {
              subTitle: '了解耳穴堂',
              linkUrl:'/aboutUS'
            }
          ]
        },
        {
          title: '下载',
          itemArr: [
            {
              subTitle: '3d耳穴工具',
              linkUrl:'https://mp.weixin.qq.com/s/WadET-46BpiCbP2PTGOhNQ'
            }
          ]
        },
        {
          title: '联系方式',
          itemArr: [
            {
              subTitle: '耳穴堂明明：18669732147',
              linkUrl:'',
              icon: require('@/assets/footer/bottom_tel_icon@2x.png')
            },
            {
              subTitle: '耳穴堂邮箱：718075406@qq.com',
              linkUrl:'',
              icon: require('@/assets/footer/bottom_email_icon@2x.png')
            },
            {
              subTitle: 'QQ群：297416224',
              linkUrl:'',
              icon: require('@/assets/footer/bottom_qq_icon@2x.png')
            },
            {
              subTitle: '山东省青岛市市北区洛阳路30号',
              linkUrl:'',
              icon: require('@/assets/footer/bottom_location_icon@2x.png')
            }
          ]
        },
      ],
      // 二维码数组
      qrCodeArr: [
        {
          title:'客服二维码',
          icon: require('@/assets/footer/qr_contact.jpeg')
        },
        {
          title:'公众号二维码',
          icon: require('@/assets/official.jpg')
        },
      ],
    // *******************弹框******************************** *//
    alertData: {},
    // 联系客服弹框数据
    contactsAlertData: {
      title1: '扫码咨询',
      title2: '请用微信扫码添加客服：耳穴堂明明',
      qrcodeUrl: require('@/assets/footer/qr_contact.jpeg'),
      title3: '请使用微信扫描二维码'
    },
    
    // *******************弹框******************************** *//

    }
  },
  methods: {
    // *******************弹框******************************** *//
    showAlertAction(alertData) {
      if (alertData) {
        this.alertData = alertData
        this.$refs.codeAlert.showSelf()
      }
    },
    // *******************弹框******************************** *//

    // *******************底部点击事件******************************** *//
    bottomTap(item) {
      if (item.linkUrl) {
        let linkUrl = item.linkUrl
        if (linkUrl.startsWith('http')) {
          window.open(linkUrl, '_blank')
        }else {
          this.$router.push(linkUrl)
          this.toTop()
        }
      }
    },
    
    toTop() {
      
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      // 实现滚动效果 
      const timeTop = setInterval(() => {
        document.body.scrollTop = document.documentElement.scrollTop = top -= 80;
        if (top <= 0) {
          clearInterval(timeTop);
        }
      }, 10);
    },
    // *******************底部点击事件******************************** *//


  }
}
</script>

<style lang="less" scoped>
  .footer-container {
    width: 100vw;
    // height: 403px;
    background-color: #1B2329;
  }

  // *******************菜单******************************** */
  .menu-item {
    margin-left: 74px;
    .title {
      margin-bottom: 21px;
      height: 22px;
      font-size: 22px;
      font-family: PingFang SC;
      font-weight: 600;
      color: #FFFFFF;
      line-height: 36px;
    }
    .sub-item {
      margin-top: 16px;
      .title2 {
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #58626A;
        &.could-tap {
          cursor: pointer; 
        }
      }
      .icon {
        width: 20px;
        height: 20px;
      }
    }
  }
  // *******************菜单******************************** */

  // *******************二维码******************************** */
  .qr-item {
    margin-left: 30px;
    .qr-img {
      width: 117px;
      height: 117px;
    }
    .qr-title {
      margin-top: 18px;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
    }
  }
  // *******************二维码******************************** */


  // *******************介绍******************************** */
  .introduce {
    padding: 67px 0 62px;
  }
  .logo-img {
    margin-right: 38px;
    width: 136px;
    height: 43px;
  }
  // *******************介绍******************************** */


  // *******************备案******************************** */
  .record-div {
    padding: 30px 0;
    width: 100vw;
    font-size: 14px;
    height: 15px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #39434B;
    line-height: 42px;
    background: #12181D;
    a {
    color: #39434B;
    img {
      margin-left: 4px;
    }
    }
  }
  // *******************备案******************************** */


  // *******************底部广告栏******************************** */
  .bottom-ad {
    z-index: 10000;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100vw;
    height: 100px;
  }
  // *******************底部广告栏******************************** */

</style>